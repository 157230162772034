import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  treatmentSelect (event) {
    this.setTreatment(event.target.value)
  }

  roomSelect (event) {
    const roomId = event.target.value

    fetch(`/rooms/${roomId}.json`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      }
    }).then(response => {
      if (response.ok) {
        return response.json().then(data => {
          document.getElementById('form_treatment_id').value = data.treatmentId
          this.setTreatment(data.treatmentId)
        })
      }
    }).catch(error => { console.error('Error:', error) })
  }

  setTreatment (treatmentId) {
    fetch(`/treatments/${treatmentId}.json`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      }
    }).then(response => {
      if (response.ok) {
        return response.json().then(data => {
          document.getElementById('form_time_period').value = data.default_time
        })
      }
    }).catch(error => { console.error('Error:', error) })
  }
}
