import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import TurboStreamController from './turbo_stream_controller'

export default class extends TurboStreamController {
  static targets = ['calendar']

  connect () {
    this.initializeCalendar()

    this.shiftsCalendarChannel = this.application.consumer.subscriptions.create(
      {
        channel: 'ShiftsCalendarChannel',
        organization_id: this.calendarTarget.dataset.organizationId
      }, {
        received: (data) => {
          switch (data.action) {
            case 'refresh':
              this.refresh()
              break
          }
        }
      }
    )
  }

  disconnect () {
    this.shiftsCalendarChannel.unsubscribe()
    this.calendar.destroy()
  }

  initializeCalendar () {
    const _this = this

    this.calendar = new Calendar(this.calendarTarget, {
      schedulerLicenseKey: FULLCALENDAR_LICENSE_KEY,
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay'
      },
      events: '/shifts.json',
      editable: true,
      navLinks: true,
      nowIndicator: true,
      expandRows: true,
      contentHeight: 'auto',
      initialDate: this.calendarTarget.dataset.calendarDate,
      initialView: 'timeGridWeek',
      slotMinTime: '07:00:00',
      slotMaxTime: '22:00:00',
      slotDuration: '00:30:00',
      slotLabelInterval: { minutes: '30' },
      slotLabelFormat: {
        hour: 'numeric',
        minute: '2-digit',
        meridiem: false,
        hour12: false
      },
      displayEventTime: false,
      allDaySlot: false,
      firstDay: 1,
      longPressDelay: 3000, // 3 seconds for drag
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
      dateClick: (info) => { _this.dateClick(info) },
      eventClick: (info) => { _this.eventClick(info) },
      eventDrop: (info) => { _this.eventDrop(info) },
      eventResize: (info) => { _this.eventResize(info) }
    })

    this.calendar.render()
  }

  dateClick (info) {
    info.jsEvent.preventDefault()

    const date = info.dateStr

    const url = new URL(`${window.location.origin}/shifts/new`)
    url.searchParams.append('form[starts_at]', date)

    this.renderTurboStream(url).then(() => document.dispatchEvent(new CustomEvent('modal:show')))
  }

  eventClick (info) {
    info.jsEvent.preventDefault()

    const url = `/shifts/${info.event.id}/edit`

    this.renderTurboStream(url).then(() => document.dispatchEvent(new CustomEvent('modal:show')))
  }

  eventResize (info) {
    const data = {
      'form[starts_at]': info.event.start,
      'form[ends_at]': info.event.end
    }

    fetch(`/shifts/${info.event.id}/calendar_move.json`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: new URLSearchParams(data).toString()
    })
  }

  eventDrop (info) {
    const data = {
      'form[starts_at]': info.event.start,
      'form[ends_at]': info.event.end
    }

    fetch(`/shifts/${info.event.id}/calendar_move.json`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: new URLSearchParams(data).toString()
    })
  }

  refresh () {
    this.calendar.refetchEvents()
  }
}
