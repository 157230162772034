import TurboStreamController from './turbo_stream_controller'
import * as bootstrap from 'bootstrap'

export default class extends TurboStreamController {
  connect () {
    this.modalElement = document.getElementById('screen-lock-modal')
    this.modal = bootstrap.Modal.getOrCreateInstance(this.modalElement)
    this.formUrl = this.modalElement.dataset.formUrl
    this.mainModalToggle = false
    this.mainModalDisplayed = false

    this.boundShow = this.show.bind(this)
    document.addEventListener('screen_lock:show', this.boundShow)

    this.boundHide = this.hide.bind(this)
    document.addEventListener('screen_lock:hide', this.boundHide)

    this.handleMainModal()

    if (this.modalElement.dataset.selectedUserId === '') {
      document.dispatchEvent(new CustomEvent('screen_lock:show'))
    }
  }

  disconnect () {
    document.removeEventListener('screen_lock:show', this.boundShow)
    document.removeEventListener('screen_lock:hide', this.boundHide)
  }

  handleMainModal () {
    this.mainModalElement = document.getElementById('mainModal')

    this.boundMainModalShown = this.mainModalShown.bind(this)
    this.boundMainModalHidden = this.mainModalHidden.bind(this)

    this.mainModalElement.addEventListener('shown.bs.modal', this.boundMainModalShown)
    this.mainModalElement.addEventListener('hidden.bs.modal', this.boundMainModalHidden)
  }

  mainModalShown () {
    this.mainModalDisplayed = true
  }

  mainModalHidden () {
    this.mainModalDisplayed = false
  }

  show () {
    this.renderTurboStream(this.formUrl).then(() => {
      if (this.mainModalDisplayed === true) {
        this.mainModalToggle = true
        document.dispatchEvent(new CustomEvent('modal:hide_for_screen_lock'))
      } else {
        this.mainModalToggle = false
      }

      this.modal.show()

      fetch(this.formUrl, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        }
      })
    })
  }

  hide () {
    this.modal.hide()

    if (this.mainModalToggle === true) {
      document.dispatchEvent(new CustomEvent('modal:show'))
    }
  }
}
