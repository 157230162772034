import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    this.boundAutocomplete = this.autocomplete.bind(this)

    document.addEventListener('autocomplete.change', this.boundAutocomplete)
  }

  disconnect () {
    document.removeEventListener('autocomplete.change', this.boundAutocomplete)
  }

  autocomplete (event) {
    if (event.target.classList.contains('form_owner_id')) {
      this.ownerSelect(event.detail.value)
    }

    if (event.target.classList.contains('form_pet_id')) {
      this.petSelect(event.detail.value)
    }
  }

  petPrefillTreatments (petId) {
    const prefillButtonElement = document.getElementById('prefill-button')

    // We are not able to prefill previous treatments for a new pet
    if (petId === 'new') {
      return
    }

    fetch(`/pets/${petId}/previous_treatments.json`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      }
    }).then(response => {
      if (response.ok) {
        prefillButtonElement.classList.remove('d-none')
      } else {
        prefillButtonElement.classList.add('d-none')
      }
    }).catch(error => console.error('Error:', error))
  }

  petSelect (petId) {
    const newPetElement = document.getElementById('new-pet')

    if (newPetElement === null) {
      return
    }

    if (petId !== 'new') {
      if (!newPetElement.classList.contains('d-none')) {
        newPetElement.classList.add('d-none')
      }

      this.petPrefillTreatments(petId)
    } else {
      newPetElement.classList.remove('d-none')
    }
  }

  ownerSelect (ownerId) {
    const newOwnerElement = document.getElementById('new-owner')

    if (ownerId !== 'new' && !newOwnerElement.classList.contains('d-none')) {
      newOwnerElement.classList.add('d-none')

      return
    }

    if (ownerId === 'new') {
      newOwnerElement.classList.remove('d-none')
    }
  }

  petKindSelect (event) {
    const petBreadElement = document.getElementById('pet-bread')
    const petBreadAutocompleteElement = document.getElementById('autocomplete-pet-bread-id')
    const petKind = event.target.value

    if (petKind === '' && !petBreadElement.classList.contains('d-none')) {
      petBreadElement.classList.add('d-none')

      return
    }

    if (petKind !== '') {
      petBreadAutocompleteElement.dataset.autocompleteUrlValue = `/pet_breads/autocomplete?kind=${petKind}`
      petBreadElement.classList.remove('d-none')
    }
  }
}
